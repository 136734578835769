import React from "react";
import image from "../Imeges/imagememories.jpg";
import bg from "../Imeges/bg.jpg";

const Punekarmemories = () => {
  return (
    <section className="bg-gradient-to-b from-white to-gray-100 py-24">
      <div className="container mx-auto px-4">
        <div className="flex flex-col lg:flex-row items-center gap-12">
          <div className="lg:w-1/2 flex flex-col items-start">
            <h2 className="text-4xl font-bold text-gray-800 mb-6">
              Punekar Yatra Company
            </h2>
            <p className="text-lg text-gray-600 leading-relaxed mb-8">
              At Punekar Yatra Company, we understand that each journey is more
              than just a destination; it's an opportunity to create lasting
              memories and forge unforgettable experiences. With our extensive
              knowledge and expertise in the travel industry, we strive to
              curate meticulously planned itineraries that showcase the best of
              India, ensuring that every moment of your trip is filled with
              excitement, discovery, and adventure.
            </p>
            <p className="text-lg text-gray-600 leading-relaxed mb-8">
              We believe in fostering meaningful connections and creating
              moments that will be cherished for a lifetime. Whether you're a
              solo traveler, a couple, a family, or a group of friends, we
              welcome you to embark on a journey of discovery with us and
              experience the richness and diversity of India like never before.
            </p>
          </div>
          <div className="lg:w-1/2">
            <div className="relative overflow-hidden rounded-lg shadow-xl">
              <img
                src={image}
                alt="Punekar Yatra Experience"
                className="w-full h-auto object-cover transition duration-300 ease-in-out transform hover:scale-105"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent"></div>
              <div className="absolute bottom-0 left-0 p-6">
                <h3 className="text-2xl font-semibold text-white mb-2">
                  Discover India
                </h3>
                <p className="text-sm text-gray-200">
                  Experience the beauty and culture of incredible India
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="lg:w-full">
        <div className="relative overflow-hidden shadow-xl ">
          <img
            src={bg}
            alt="Punekar Yatra Experience"
            className="w-full h-96 object-cover "
          />
          <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center"></div>
          <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent"></div>
          <div className="absolute inset-0 flex flex-col justify-center items-center p-6 text-center">
            <h3 className="text-3xl font-semibold text-white mb-4">
              Unlock the beauty of India with Punekar Yatra Company
            </h3>
            <p className="text-lg text-gray-200 mb-4">
              Your passport to unforgettable adventures!
            </p>
            <p className="text-lg text-gray-200">
              Call us now at <span className="bg-orange-500 rounded-lg px-2">+91 8055358080</span> to start your journey.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Punekarmemories;
