import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./Navbar/Navbar";
import HomePage from "./Components/HomePage";
import AboutUsPage from "./Components/AboutUsPage";
import ServicesPage from "./Components/ServicesPage";
import GallaryPage from "./Components/GallaryPage";
import ContactPage from "./Components/ContactPage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Navbar />}>
          <Route index element={<HomePage />} />
          <Route path="/about-us" element={<AboutUsPage />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/gallery" element={<GallaryPage />} />
          <Route path="/contact" element={<ContactPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
