import React from "react";
import { MapPin, Mail, Phone } from "lucide-react";
import logo from "../Imeges/punekarlogo.png";

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Logo Section */}
          <div className="flex items-center justify-center md:justify-start">
            <img
              src={logo}
              alt="Punekar Yatra Logo"
              className="h-44 w-auto bg-white rounded-full"
            />{" "}
            {/* Adjust height and width here */}
          </div>
          {/* Contact Information */}
          <div className="text-left">
            <p className="mb-2 flex items-center justify-center">
              <MapPin className="w-5 h-5 mr-2" />
              Panderi Nath Nagar, Shop No. 4, NDA RD.
            </p>
            <p className="mb-2 flex items-center justify-center">
              <MapPin className="w-5 h-5 mr-2" />
              Shinde Pool, Shivne, Pune-4110023
            </p>
            <p className="mb-2 flex items-center justify-center">
              <Mail className="w-5 h-5 mr-2" />
              punekaryatraco@gmail.com
            </p>
            <p className="flex items-center justify-center">
              <Phone className="w-5 h-5 mr-2" />
              +91 8055358080 | +91 9529111752
            </p>
          </div>
          {/* About Section */}
          <div className="text-right hidden md:block">
            <h3 className="text-lg font-semibold mb-2">About Punekar Yatra</h3>
            <p>
              Punekar Yatra Company, your gateway to unforgettable journeys
              through Pune's rich cultural tapestry and scenic landscapes. Let
              us guide you to the heart of Maharashtra's vibrant spirit, one
              adventure at a time.
            </p>
          </div>
        </div>
        {/* Copyright Section */}
        <div className="mt-8 pt-8 border-t border-gray-700 text-center">
          <p className="text-sm">
            Copyright © 2024 Punekar Yatra | Powered by{" "}
            <a href="https://exads.in/"> EXADS IT SOLUTIONS PVT.</a>
            LTD
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
