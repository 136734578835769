import React from "react";
import { Calendar, Briefcase, DollarSign, Plane } from "lucide-react";
import bg from "../Imeges/bg-1.jpg";

const PunekarYatra = () => {
  return (
    <section
      className="relative bg-cover bg-center min-h-[600px] w-full py-12"
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className="absolute inset-0 bg-gradient-to-b from-blue-900 to-blue-600 opacity-60"></div>
      <div className="relative z-10 h-full flex flex-col items-center justify-center px-4 sm:px-6 lg:px-8">
        <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold text-white mb-8 text-center">
          Why Punekar Yatra?
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6 lg:gap-8 max-w-7xl w-full">
          {[
            { icon: Calendar, text: "Easy Online Booking Available" },
            { icon: Briefcase, text: "One Stop for All Travel Services" },
            { icon: Plane, text: "Instant Deals on Flights & Holidays" },
            { icon: DollarSign, text: "Assured Best Fares on all Services" },
          ].map((item, index) => (
            <div
              key={index}
              className="bg-white bg-opacity-10 backdrop-blur-lg rounded-lg p-4 sm:p-6 flex flex-col items-center justify-center text-center transform transition duration-500 hover:scale-105 hover:bg-opacity-20"
            >
              <item.icon className="w-8 h-8 sm:w-10 sm:h-10 lg:w-12 lg:h-12 text-yellow-300 mb-2 sm:mb-4" />
              <p className="text-white text-sm sm:text-base lg:text-lg font-semibold">{item.text}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PunekarYatra;