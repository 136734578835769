import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight, User } from 'lucide-react';

const testimonials = [
  { name: "Mr. Dipak", text: "Exceptional service and attention to detail. Punekar Yatra made our dream vacation a reality." },
  { name: "Mrs. Shaha", text: "Impeccably planned itinerary and knowledgeable guides. Punekar Yatra Company exceeded all our travel expectations." },
  { name: "Mr. Manoj D.", text: "From start to finish, Punekar Yatra Company ensured our trip was seamless, leaving us with unforgettable experiences." },
  { name: "Sweta Sharma", text: "Highly recommend Punekar Yatra for their professionalism, reliability, and dedication to creating magical journeys." },
  { name: "Mr. Biju K.", text: "Thanks to Punekar Yatra Company, our vacation was nothing short of perfection - they truly go above and beyond for their clients." },
];

const TestimonialSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 5000); // Auto-slide every 5 seconds
    return () => clearInterval(interval);
  }, []);

  const visibleTestimonials = [
    testimonials[currentIndex],
    testimonials[(currentIndex + 1) % testimonials.length],
    testimonials[(currentIndex + 2) % testimonials.length]
  ];

  return (
    <div className="bg-gradient-to-r from-blue-50 to-indigo-50 py-8 sm:py-12 md:py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-2xl sm:text-3xl md:text-4xl font-extrabold text-gray-900 text-center mb-8 sm:mb-12">
          What Our Customers Say
        </h2>
        <div className="relative">
          <div className="overflow-hidden">
            <div className="flex flex-col sm:flex-row">
              {visibleTestimonials.map((testimonial, index) => (
                <div key={index} className={`w-full sm:w-1/2 md:w-1/3 px-4 mb-6 sm:mb-0 ${index === 0 ? 'block' : 'hidden sm:block'}`}>
                  <div className="bg-white rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300 p-4 sm:p-6 h-full flex flex-col items-center">
                    <div className="w-16 h-16 sm:w-20 sm:h-20 bg-gradient-to-r from-blue-400 to-indigo-500 rounded-full flex items-center justify-center mb-4 shadow-md">
                      <User className="h-8 w-8 sm:h-10 sm:w-10 text-white" />
                    </div>
                    <p className="font-bold text-base sm:text-lg text-gray-900 mb-2">{testimonial.name}</p>
                    <p className="text-gray-600 text-center text-xs sm:text-sm italic">&ldquo;{testimonial.text}&rdquo;</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <button
            onClick={prevSlide}
            className="absolute top-1/2 left-0 sm:-left-4 transform -translate-y-1/2 bg-white p-1 sm:p-2 rounded-full shadow-lg hover:bg-gray-100 focus:outline-none transition-colors duration-300"
            aria-label="Previous testimonial"
          >
            <ChevronLeft className="h-4 w-4 sm:h-6 sm:w-6 text-gray-600" />
          </button>
          <button
            onClick={nextSlide}
            className="absolute top-1/2 right-0 sm:-right-4 transform -translate-y-1/2 bg-white p-1 sm:p-2 rounded-full shadow-lg hover:bg-gray-100 focus:outline-none transition-colors duration-300"
            aria-label="Next testimonial"
          >
            <ChevronRight className="h-4 w-4 sm:h-6 sm:w-6 text-gray-600" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default TestimonialSlider;