import React, { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import contact from "../Imeges/contact.jpg";
import WhatsApp from "../WhatsApp";

function ContactPage() {
  useEffect(() => {
    emailjs.init("MBkkSOxfyvdDMHMrJ");
  }, []);

  const [formData, setFormData] = useState({
    fullName: "",
    mobileNumber: "",
    message: "",
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const serviceId = "service_ofn5amj";
      const templateId = "template_yoo0sln";

      await emailjs.send(serviceId, templateId, {
        from_name: formData.fullName,
        mobile_number: formData.mobileNumber,
        message: formData.message,
      });

      alert("Form submitted successfully!");
      setFormData({ fullName: "", mobileNumber: "", message: "" });
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("An error occurred. Please try again later.");
    }
  };

  return (
    <div className="flex flex-col md:flex-row justify-between items-center p-4 md:p-10">
      {/* Contact Form */}
      <div className="w-full md:w-1/2 bg-white shadow-lg rounded-lg p-6 md:p-8 mb-8 md:mb-0">
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              className="block text-gray-600 text-sm mb-2"
              htmlFor="fullName"
            >
              Enter Your Full Name
            </label>
            <input
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-indigo-500"
              type="text"
              id="fullName"
              value={formData.fullName}
              onChange={handleChange}
              placeholder="Enter Your Full Name"
              required
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-600 text-sm mb-2"
              htmlFor="mobileNumber"
            >
              Enter Your Mobile Number
            </label>
            <input
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-indigo-500"
              type="tel"
              id="mobileNumber"
              value={formData.mobileNumber}
              onChange={handleChange}
              placeholder="Enter Your Mobile Number"
              required
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-600 text-sm mb-2"
              htmlFor="message"
            >
              Enter Message
            </label>
            <textarea
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-indigo-500"
              id="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="Enter Message"
              required
            ></textarea>
          </div>
          <button
            className="w-full bg-green-600 text-white font-bold py-2 px-4 rounded hover:bg-green-700 transition duration-300"
            type="submit"
          >
            SUBMIT
          </button>
        </form>
      </div>

      {/* Illustration */}
      <div className="w-full md:w-1/2 flex justify-center">
        <img
          src={contact}
          alt="Contact Illustration"
          className="h-auto max-w-full rounded-lg"
        />
      </div>
      <WhatsApp />
    </div>
  );
}

export default ContactPage;
