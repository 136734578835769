import React from "react";
import hotel1 from "../Imeges/Hotel1.jpg";
import international from "../Imeges/international.jpg";
import bg from "../Imeges/bg-services.jpg";
import car from "../Imeges/Car.jpg";
import box from "../Imeges/box.jpg";
import { useNavigate } from "react-router-dom";
import WhatsApp from "../WhatsApp";

const TravelServices = () => {
  const navigate = useNavigate();
  const services = [
    {
      title: "Hotel Booking",
      description:
        "Say goodbye to sleepless nights, as we'll make sure your home away from home is nothing but delightful. Double-checked by us for quality and value for your money, each hotel we recommend will live up to your expectations. Carefully handpicked depending on your needs, we'll offer you the perfect stay with a deal that's as perfect.",
      imageUrl: hotel1,
      imageAlt: "Luxurious hotel with pool",
    },
    {
      title: "Domestic & International Ticketing",
      description:
        "Experience leads to perfection. And we've been perfecting the art of ticketing for decades now. Whether domestic or international, our expertise will assist you in getting only the best deals and prices on flights. Our years of knowledge will also help you pick smarter travel routes, so that you can spend less time travelling and more time enjoying.",
      imageUrl: international,
      imageAlt: "Airplane flying over a city",
    },
  ];

  const support = [
    {
      title: "Car Rental",
      description:
        "Luxury and comfort can go hand in hand. You�ll experience this in the backseat of our cars. Chauffeur driven, reliable and spick and span, pick from our range of rental cars as per your need. From local and outstation travel to sightseeing, weddings, airport pick-ups and drops, we'll be at your service for any journey. And in case you get used to the pampering, you can also book our cars on a contract basis for an entire month.",
      imageUrl: car,
      imageAlt: "Car Rental",
    },
    {
      title: "Customized Tour Package",
      description:
        "At My Dreams Holidays, we believe that every traveler deserves a vacation that reflects their dreams. That is why we go above and beyond to understand your desires and create a customized tour package that surpasses your expectations. With My Dreams Holidays, you have the power to create your own itinerary, perfectly suited to your preferences and interests.",
      imageUrl: box,
      imageAlt: "Customized Tour Package",
    },
  ];

  return (
    <section>
      <div className="container mx-auto px-4 py-8 space-y-12">
        {services.map((service, index) => (
          <div
            key={index}
            className={`flex flex-col ${
              index % 2 === 0 ? "md:flex-row" : "md:flex-row-reverse"
            } items-center bg-white rounded-3xl overflow-hidden shadow-lg`}
          >
            <div className="md:w-1/2">
              <img
                src={service.imageUrl}
                alt={service.imageAlt}
                className={`w-full h-full object-cover ${
                  index % 2 === 0 ? "rounded-tr-[100px]" : "rounded-tl-[100px]"
                }`}
              />
            </div>
            <div className="md:w-1/2 p-8">
              <h2 className="text-4xl font-bold text-red-500 mb-4">
                {service.title}
              </h2>
              <p className="text-gray-700">{service.description}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="lg:w-full">
        <div className="relative overflow-hidden shadow-xl ">
          <img
            src={bg}
            alt="Punekar Yatra Experience"
            className="w-full h-80 object-cover "
          />
          <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center"></div>
          <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent"></div>
          <div className="absolute inset-0 flex flex-col justify-center items-center p-6 text-center">
            <h3 className="text-3xl font-semibold text-white mb-4">
              Embark on unforgettable journeys with Punekar Yatra Company
            </h3>
            <p className="text-lg text-gray-200 mb-4">
              where every destination becomes an adventure!
            </p>
            <button
              onClick={() => {
                navigate("/contact");
              }}
              className="bg-white p-2 px-8 rounded-2xl text-orange-500"
            >
              <b>Book Your Trip</b>
            </button>
          </div>
        </div>
      </div>
      <div className="container mx-auto px-4 py-8 space-y-12">
        {support.map((support, index) => (
          <div
            key={index}
            className={`flex flex-col ${
              index % 2 === 0 ? "md:flex-row" : "md:flex-row-reverse"
            } items-center bg-white rounded-3xl overflow-hidden shadow-lg`}
          >
            <div className="md:w-1/2">
              <img
                src={support.imageUrl}
                alt={support.imageAlt}
                className={`w-full h-full object-cover ${
                  index % 2 === 0 ? "rounded-tr-[100px]" : "rounded-tl-[100px]"
                }`}
              />
            </div>
            <div className="md:w-1/2 p-8">
              <h2 className="text-4xl font-bold text-red-500 mb-4">
                {support.title}
              </h2>
              <p className="text-gray-700">{support.description}</p>
            </div>
          </div>
        ))}
      </div>
      <WhatsApp/>
    </section>
  );
};

export default TravelServices;
