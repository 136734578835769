import React from "react";

import icon1 from "../Imeges/service1.png";
import icon2 from "../Imeges/service2.png";
import icon3 from "../Imeges/service3.png";
import icon4 from "../Imeges/service4.png";
import icon5 from "../Imeges/service5.png";
import icon6 from "../Imeges/service6.png";
import icon7 from "../Imeges/service7.png";
import icon8 from "../Imeges/service8.png";
import icon9 from "../Imeges/service9.png";

const services = [
  {
    title: "Ticketing",
    description: "Domestic and International tickets at best prices",
    icon: icon1,
  },
  {
    title: "Customized Holidays",
    description: "You name it. We design it. At the most affordable rates",
    icon: icon2,
  },
  {
    title: "Celebrity Tour Management",
    description: "Hassle Free solutions from start to end",
    icon: icon3,
  },
  {
    title: "Destination Wedding",
    description:
      "Premium help with all your on-site personalized wedding needs",
    icon: icon4,
  },
  {
    title: "Corporate Conferences",
    description:
      "Creating memorable events for your business strategies and let you be creative",
    icon: icon5,
  },
  {
    title: "Event Management",
    description: "Enjoy your moments. Leave your Event management to us.",
    icon: icon6,
  },
  {
    title: "Car Decoration",
    description: "Decorate car for Wedding Ceremony.",
    icon: icon7,
  },
  {
    title: "Travel Insurance",
    description: "Covers risks during travel protect you and your family",
    icon: icon8,
  },
  {
    title: "Cruise Bookings",
    description: "Plan the Trip of a Lifetime Explore Unique Itineraries",
    icon: icon9,
  },
];

const ServiceCard = ({ title, description, icon }) => (
  <div className="bg-white rounded-lg shadow-lg p-6 transition-all duration-300 hover:shadow-xl hover:scale-105">
    <img src={icon} alt={title} className="w-16 h-16 mb-4 mx-auto" />
    <h3 className="text-xl font-semibold text-center mb-2">{title}</h3>
    <p className="text-gray-700 text-center">{description}</p>
  </div>
);

const ServicesGrid = () => (
  <div className="bg-gray-100 min-h-screen py-12 px-4 sm:px-6 lg:px-8">
    <div className="max-w-7xl mx-auto">
      <h2 className="text-3xl font-extrabold text-gray-900 text-center mb-12">
        Services We Provide
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {services.map((service, index) => (
          <ServiceCard key={index} {...service} />
        ))}
      </div>
    </div>
  </div>
);

export default ServicesGrid;
