import React from "react";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import logo from "./Imeges/logo.png";

function WhatsApp() {
  return (
    <div>
      <FloatingWhatsApp
        phoneNumber="8055358080"
        accountName="Punekar Yatra Company"
        allowEsc
        allowClickAway
        notification
        notificationSound
        avatar={logo}
      />
    </div>
  );
}

export default WhatsApp;
