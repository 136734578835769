import React from "react";
import logo from '../Imeges/logo.png';
import video from "../Imeges/Hero.mp4";
import TravelExperienceSection from "./TravelExperienceSection";
import Punekarmemories from "./Punekarmemories";
import ServicesGrid from "./ServicesGrid";
import PunekarYatra from "./WhyPunekarYatra";
import TestimonialSlider from "./testimonials";
import ExploreOurCollection from "./ExploreOurCollection";
import  FloatingWhatsApp  from "../WhatsApp";

function HomePage() {
  return (
    <div className="flex flex-col min-h-screen">
      <div className="relative h-screen">
        <video
          src={video}
          autoPlay
          loop
          muted
          playsInline
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          {/* Logo watermark */}
          <img
            src={logo}
            alt="Logo"
            className="absolute top-4 left-4 w-24 h-auto opacity-70"
          />
        </div>
      </div>

      <div className="bg-gray-100 py-16">
        <h2 className="text-4xl font-bold text-center mb-12 text-gray-800">
          Explore Our Collection
        </h2>
        <ExploreOurCollection />
      </div>
      <TravelExperienceSection />
      <Punekarmemories />
      <ServicesGrid />
      <PunekarYatra />
      <TestimonialSlider />
      <FloatingWhatsApp/>
    </div>
  );
}

export default HomePage;