import React from "react";
import {
  MapPin,
  Car,
  Users,
  Calendar,
  Star,
  ThumbsUp,
  Target,
  Eye,
} from "lucide-react";
import Punekarmemories from "./Punekarmemories";
import TestimonialSlider from "./testimonials";
import { useNavigate } from "react-router-dom";
import WhatsApp from "../WhatsApp";

function AboutUsPage() {
  const navigate = useNavigate();
  return (
    <section>
      <div className="bg-gradient-to-br from-blue-50 to-indigo-100 min-h-screen p-8">
        <div className="max-w-6xl mx-auto">
          <header className="text-center mb-12">
            <h1 className="text-4xl font-bold text-indigo-800 mb-4">
              About Punekar Yatra Company
            </h1>
            <p className="text-xl text-gray-600">
              Your Trusted Partner in Exploring India
            </p>
          </header>

          <section className="bg-white rounded-lg shadow-lg p-8 mb-12">
            <h2 className="text-3xl font-semibold text-indigo-700 mb-6">
              Our Story
            </h2>
            <p className="text-gray-700 leading-relaxed mb-4">
              Established in 2012, Punekar Yatra Company has been at the
              forefront of providing unparalleled travel experiences, tailored
              to meet the unique preferences and interests of our valued
              clients. We are a premier Pan-India travel management and car
              rental service, dedicated to ensuring seamless and unforgettable
              journeys across the diverse landscapes of India.
            </p>
            <p className="text-gray-700 leading-relaxed">
              With a focus on excellence and customer satisfaction, we offer a
              wide array of services designed to cater to the diverse needs of
              travelers throughout the country. From meticulously planned tours
              to reliable and comfortable car rental solutions, we take care of
              every aspect of your journey with utmost professionalism and
              attention to detail.
            </p>
          </section>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
            <div className="bg-white rounded-lg shadow-lg p-6">
              <Target className="text-indigo-500 w-12 h-12 mb-4" />
              <h3 className="text-2xl font-semibold text-indigo-700 mb-2">
                Our Mission
              </h3>
              <p className="text-gray-600">
                To inspire and facilitate seamless travel experiences across
                India, providing exceptional travel management and car rental
                services with utmost comfort, safety, and satisfaction for our
                customers.
              </p>
            </div>
            <div className="bg-white rounded-lg shadow-lg p-6">
              <Eye className="text-indigo-500 w-12 h-12 mb-4" />
              <h3 className="text-2xl font-semibold text-indigo-700 mb-2">
                Our Vision
              </h3>
              <p className="text-gray-600">
                To redefine the standards of excellence in Pan-India travel
                management and car rental services, enabling every traveler to
                explore India's rich cultural heritage, natural wonders, and
                vibrant communities with ease and confidence.
              </p>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
            <div className="bg-white rounded-lg shadow-lg p-6">
              <MapPin className="text-indigo-500 w-12 h-12 mb-4" />
              <h3 className="text-2xl font-semibold text-indigo-700 mb-2">
                Pan-India Coverage
              </h3>
              <p className="text-gray-600">
                Explore every corner of India with our comprehensive travel
                services.
              </p>
            </div>
            <div className="bg-white rounded-lg shadow-lg p-6">
              <Car className="text-indigo-500 w-12 h-12 mb-4" />
              <h3 className="text-2xl font-semibold text-indigo-700 mb-2">
                Premium Fleet
              </h3>
              <p className="text-gray-600">
                Travel in comfort with our well-maintained vehicles and
                experienced drivers.
              </p>
            </div>
            <div className="bg-white rounded-lg shadow-lg p-6">
              <Users className="text-indigo-500 w-12 h-12 mb-4" />
              <h3 className="text-2xl font-semibold text-indigo-700 mb-2">
                Personalized Approach
              </h3>
              <p className="text-gray-600">
                Tailored experiences for solo travelers, families, and groups
                alike.
              </p>
            </div>
            <div className="bg-white rounded-lg shadow-lg p-6">
              <Calendar className="text-indigo-500 w-12 h-12 mb-4" />
              <h3 className="text-2xl font-semibold text-indigo-700 mb-2">
                Hassle-free Planning
              </h3>
              <p className="text-gray-600">
                Let us handle the details while you focus on creating memories.
              </p>
            </div>
          </div>

          <section className="bg-indigo-700 text-white rounded-lg shadow-lg p-8 mb-12">
            <h2 className="text-3xl font-semibold mb-6">
              Why Choose Punekar Yatra?
            </h2>
            <ul className="space-y-4">
              <li className="flex items-center">
                <Star className="w-6 h-6 mr-2" />
                <span>Unparalleled expertise in Indian travel since 2012</span>
              </li>
              <li className="flex items-center">
                <Star className="w-6 h-6 mr-2" />
                <span>Customized itineraries to suit your preferences</span>
              </li>
              <li className="flex items-center">
                <Star className="w-6 h-6 mr-2" />
                <span>Safe and comfortable transportation options</span>
              </li>
              <li className="flex items-center">
                <Star className="w-6 h-6 mr-2" />
                <span>Dedicated customer support throughout your journey</span>
              </li>
            </ul>
          </section>

          <footer className="text-center">
            <p className="text-xl text-indigo-800 font-semibold mb-4">
              Ready to embark on your next adventure?
            </p>
            <button
              onClick={() => {
                navigate("/contact");
              }}
              className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-3 px-6 rounded-full transition duration-300 ease-in-out transform hover:scale-105 flex items-center justify-center mx-auto"
            >
              <ThumbsUp className="w-5 h-5 mr-2" />
              Contact Us Today
            </button>
          </footer>
        </div>
      </div>
      <Punekarmemories />
      <TestimonialSlider />
      <WhatsApp />
    </section>
  );
}

export default AboutUsPage;
