import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Assume these imports are correct
import video1 from "../Imeges/INDIA.mp4";
import image1 from "../Imeges/image1.jpg";
import image2 from "../Imeges/image2.jpg";
import image3 from "../Imeges/image3.jpg";
import logo from "../Imeges/logo.png";

const TravelExperienceSection = () => {
  const videoRef = useRef(null);
  const navigate = useNavigate();
  const [hoveredImage, setHoveredImage] = useState(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play().catch((error) => {
        console.error("Autoplay was prevented:", error);
      });
    }
  }, []);

  const imageData = [
    {
      src: image1,
      alt: "Main Travel Experience",
      text: "Explore Himachal's Hidden Gems",
      describe:
        "Discover the Magic of Himachal Pradesh with Himalayan Adventures",
    },
    {
      src: image2,
      alt: "Additional Image 1",
      text: "Discover the Soul of South India",
    },
    {
      src: image3,
      alt: "Additional Image 2",
      text: "Your Beach Adventure Awaits!",
    },
  ];

  return (
    <section className="bg-gradient-to-b from-white to-gray-100 py-24">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-16 text-gray-800">
          Discover Unforgettable{" "}
          <span className="text-blue-600">Travel Experiences</span>
        </h2>

        <div className="flex flex-col lg:flex-row items-center gap-12">
          {/* Left side: Image Gallery */}
          <div className="lg:w-1/2 grid grid-cols-6 grid-rows-5 gap-4 h-[600px]">
            {imageData.map((image, index) => (
              <div
                key={index}
                className={`relative overflow-hidden rounded-lg shadow-lg ${
                  index === 0
                    ? "col-span-4 row-span-4"
                    : index === 1
                    ? "col-span-2 row-span-4"
                    : "col-span-6 row-span-1"
                }`}
                onMouseEnter={() => setHoveredImage(index)}
                onMouseLeave={() => setHoveredImage(null)}
              >
                <img
                  src={image.src}
                  alt={image.alt}
                  className="object-cover w-full h-full transition-transform duration-300 ease-in-out transform hover:scale-110"
                />
                <div
                  className={`absolute inset-0 bg-black bg-opacity-50 flex flex-col items-center justify-center transition-opacity duration-300 ease-in-out ${
                    hoveredImage === index ? "opacity-100" : "opacity-0"
                  }`}
                >
                  <h2 className="text-white text-center text-2xl font-bold mb-2 px-4">
                    {image.text}
                  </h2>
                  <p className="text-gray-200 text-center text-sm px-4">
                    {image.describe}
                  </p>
                </div>
              </div>
            ))}
          </div>

          {/* Right side: Video and Content */}
          <div className="lg:w-1/2 flex flex-col items-center">
            <div className="text-center mb-8 bg-cyan-200 p-5 rounded-lg">
              <h3 className="text-3xl font-bold text-gray-800 mb-4">
                BEST TOURS AND TRAVELS
              </h3>
              <p className="text-xl text-gray-600 leading-relaxed">
                Embark on the Journey of a Lifetime with Wanderlust Travels:
                Where Adventure Knows No Bounds!
              </p>
            </div>
            <div className="relative w-full aspect-video rounded-lg shadow-xl overflow-hidden">
              <video
                ref={videoRef}
                src={video1}
                className="w-full h-full object-cover"
                loop
                muted
                playsInline
              />
              <img
                src={logo}
                alt="Logo"
                className="absolute top-4 right-4 w-16 h-16 opacity-70"
              />
            </div>
            <div className="mt-8 text-center">
              <button
                onClick={() => navigate("/contact")}
                className="bg-blue-600 text-white font-semibold py-3 px-8 rounded-full shadow-lg hover:bg-blue-700 transition-colors duration-300"
              >
                Start Your Adventure
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TravelExperienceSection;
